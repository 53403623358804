<template>
  <b-container class="mt-5">
    <h2>Generar Listas de precios</h2>

    <div class="d-flex justify-content-left mb-4 mt-5">
      <h4>Formato:</h4>
      <b-form-radio v-model="formato" name="formatos" value="excel" class="mx-3"
        >Excel</b-form-radio
      >
      <b-form-radio v-model="formato" name="formatos" value="pdf"
        >PDF</b-form-radio
      >
    </div>

    <article class="d-flex justify-content-left mb-4" v-if="soyAdmin">
      <h4>Cliente:</h4>
      <BaseSelectCliente
        style="margin: 0px"
        class="ml-3"
        @select-cliente="getProveedores"
      />
    </article>

    <div class="d-flex justify-content-left mb-4">
      <h4 style="text-wrap: nowrap">Generar por:</h4>
      <b-form-select
        v-model="generarPor"
        :options="options"
        class="ml-3"
      ></b-form-select>
    </div>

    <div class="d-flex justify-content-left mb-4 align-items-center">
      <h4 style="text-wrap: nowrap" class="mr-2">Proveedor:</h4>
      <b-spinner small v-if="loading"></b-spinner>
      <b-form-select v-model="proveedor" :options="proveedores" class="ml-3">
      </b-form-select>
    </div>

    <div class="d-flex justify-content-left mb-4" v-if="generarPor === 'marca'">
      <h4 style="text-wrap: nowrap">Marca:</h4>
      <b-form-select
        v-model="marca"
        :options="marcas"
        class="ml-3"
      ></b-form-select>
    </div>

    <div
      class="d-flex justify-content-left mb-4"
      v-if="generarPor === 'familia'"
    >
      <h4 style="text-wrap: nowrap">Familia:</h4>
      <b-form-input v-model="familia" class="ml-3" type="text"></b-form-input>
    </div>

    <b-button type="submit" v-on:click="generar" variant="primary"
      >Generar</b-button
    >

    <article>
      <b-table-simple
        small
        bordered
        style="display: none; max-width: 793px; font-size: 8px"
        class="px-5"
        id="tablaPDF"
      >
        <b-thead>
          <b-tr>
            <b-th>Código</b-th>
            <b-th>Subcódigo</b-th>
            <b-th>Descripción</b-th>
            <b-th>Proveedor</b-th>
            <b-th>Código de barras</b-th>
            <b-th>Marca</b-th>
            <b-th>Precio</b-th>
            <b-th>Precio con IVA</b-th>
            <b-th>Venta mínima</b-th>
            <b-th>Empaque</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(producto, index) of datos" :key="index">
            <b-td>{{ producto.codigo }}</b-td>
            <b-td>{{ producto.subcodigo }}</b-td>
            <b-td>{{ producto.descripcion }}</b-td>
            <b-td>{{ producto.proveedor }}</b-td>
            <b-td>{{ producto.codigoBarras }}</b-td>
            <b-td>{{ producto.marca }}</b-td>
            <b-td>{{ producto.precio }}</b-td>
            <b-td>{{ producto.precioIva }}</b-td>
            <b-td>{{ producto.ventaMinima }}</b-td>
            <b-td>{{ producto.empaque }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </article>
  </b-container>
</template>

<script>
import { jsPDF } from "jspdf";
import { axiosInstance } from "../../utils/axiosInstance";
export default {
  data() {
    return {
      familia: null,
      formato: null,
      marcas: [{ value: null, text: "Seleccione una marca" }],
      marca: null,
      proveedores: [{ value: null, text: "Seleccione un proveedor" }],
      proveedor: null,
      generarPor: "proveedor",
      options: [
        { value: "proveedor", text: "Proveedor" },
        { value: "marca", text: "Marca" },
        { value: "familia", text: "Familia (descripción)" },
        { value: "oferta", text: "Oferta" },
        { value: "nuevos", text: "Nuevos" },
      ],
      loading: false,
      cliente: "",
      datos: [],
    };
  },
  mounted() {
    this.loading = true;
    if (!this.soyAdmin) {
      axiosInstance
        .get("/proveedores/cliente", {
          params: { usuario_id: sessionStorage.getItem("usuario_id") },
          headers: {
            authorization: localStorage.getItem("token"),
          },
        })
        .then((result) => {
          result.data.forEach((proveedor) => {
            this.proveedores.push({
              value: proveedor.proveedor_id,
              text: proveedor.proveedor.nombre,
            });
          });
        })
        .catch(() => {
          this.makeToast(
            "Consulta proveedores",
            "Hubo un error al obtener los proveedores disponibles",
            "danger",
            2000
          );
        });
    }

    axiosInstance
      .get("/marcas", {
        params: { suspendido: 0 },
        headers: {
          authorization: localStorage.getItem("token"),
        },
      })
      .then((result) => {
        result.data.forEach((marca) => {
          this.marcas.push({ value: marca.marca_id, text: marca.nombre });
        });
      })
      .catch(() => {
        this.makeToast(
          "Consulta de marcas",
          "Hubo un error al consultar las marcas",
          "danger",
          2000
        );
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || "Toast body content";
      this.$bvToast.toast(body, {
        title: title || "default",
        toaster: "b-toaster-top-center",
        variant: variant || "default",
        autoHideDelay: duration,
        solid: true,
      });
    },
    async generar() {
      if (!this.proveedor) {
        this.makeToast(
          "Datos incompletos",
          "Por favor seleccione un proveedor",
          "warning",
          2000
        );
        return;
      }

      if (!this.formato) {
        this.makeToast(
          "Datos incompletos",
          "Por favor seleccione un formato",
          "warning",
          2000
        );
        return;
      }

      if (this.generarPor === "marca" && !this.marca) {
        this.makeToast(
          "Datos incompletos",
          "Por favor seleccione una marca",
          "warning",
          2000
        );
        return;
      }

      if (
        this.generarPor === "familia" &&
        (!this.familia || this.familia === "")
      ) {
        this.makeToast(
          "Datos incompletos",
          "Por favor escriba una familia",
          "warning",
          2000
        );
        return;
      }

      switch (this.generarPor) {
        case "proveedor":
          this.generarProveedor();
          break;
        case "marca":
          this.generarMarca();
          break;
        case "familia":
          this.generarFamilia();
          break;
        case "oferta":
          this.generarOferta();
          break;
        default:
          this.generarNuevos();
          break;
      }
    },
    crearPDF(productos, precio_cliente) {
      const doc = new jsPDF({ unit: "px", hotfixes: ["px_scaling"] });

      this.datos = productos.map((producto) => {
        let precio;

        switch (precio_cliente.precio) {
          case 1:
            precio = producto.precio.precio_1;
            if (producto.oferta.length) {
              if (producto.oferta[0].tipo === "porcentaje") {
                precio = producto.precio.precio_1;
                precio =
                  precio -
                  precio * (parseInt(producto.oferta[0].porcentaje) / 100);
              } else {
                precio = producto.oferta[0].precio_1;
              }
            }
            break;
          case 2:
            precio = producto.precio.precio_2;
            if (producto.oferta.length) {
              if (producto.oferta[0].tipo === "porcentaje") {
                precio = producto.precio.precio_2;
                precio =
                  precio -
                  precio * (parseInt(producto.oferta[0].porcentaje) / 100);
              } else {
                precio = producto.oferta[0].precio_2;
              }
            }
            break;
          case 3:
            precio = producto.precio.precio_3;
            if (producto.oferta.length) {
              if (producto.oferta[0].tipo === "porcentaje") {
                precio = producto.precio.precio_3;
                precio =
                  precio -
                  precio * (parseInt(producto.oferta[0].porcentaje) / 100);
              } else {
                precio = producto.oferta[0].precio_3;
              }
            }
            break;
          case 4:
            precio = producto.precio.precio_4;
            if (producto.oferta.length) {
              if (producto.oferta[0].tipo === "porcentaje") {
                precio = producto.precio.precio_4;
                precio =
                  precio -
                  precio * (parseInt(producto.oferta[0].porcentaje) / 100);
              } else {
                precio = producto.oferta[0].precio_4;
              }
            }
            break;
          default:
            precio = producto.precio.precio_5;
            if (producto.oferta.length) {
              if (producto.oferta[0].tipo === "porcentaje") {
                precio = producto.precio.precio_5;
                precio =
                  precio -
                  precio * (parseInt(producto.oferta[0].porcentaje) / 100);
              } else {
                precio = producto.oferta[0].precio_5;
              }
            }
            break;
        }

        return {
          codigo: producto.codigo || "",
          subcodigo: producto.subcodigo || "",
          descripcion: producto.descripcion || "",
          proveedor: producto.proveedor.nombre || "",
          codigoBarras: producto.codigo_fab || "",
          marca: producto.marca.nombre || "",
          precio: precio.toFixed(2) || "",
          precioIva: (
            parseFloat(precio) +
            parseFloat(precio) * parseFloat(producto.iva)
          ).toFixed(2),
          ventaMinima: producto.venta_min || "",
          empaque: producto.empaque || "",
        };
      });

      document.getElementById("tablaPDF").style.display = "block";

      doc.html(document.getElementById("tablaPDF"), {
        callback: (doc) => {
          doc.save(
            `Lista de precios - ${
              this.proveedores.find(
                (proveedor) => proveedor.value === this.proveedor
              ).text
            }.pdf`
          );
          document.getElementById("tablaPDF").style.display = "none";
        },
        autoPaging: "text",
        margin: [50, 0, 50, 0],
      });
    },
    crearExcel(stream) {
      const url = window.URL.createObjectURL(new Blob([stream]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `ListaPrecios-${
          this.proveedores.find(
            (proveedor) => proveedor.value === this.proveedor
          ).text
        }.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async generarProveedor() {
      if (this.formato === "excel") {
        const stream = await axiosInstance.post(
          "listas-precios/proveedor",
          {
            formato: this.formato,
            proveedor: this.proveedor,
            usuario_id: this.soyAdmin
              ? this.cliente
              : localStorage.getItem("usuario_id"),
          },
          {
            responseType: "blob",
            headers: {
              authorization: localStorage.getItem("token"),
            },
          }
        );

        this.crearExcel(stream.data);
      } else {
        try {
          const {
            data: { productos, precio_cliente },
          } = await axiosInstance.post(
            "listas-precios/proveedor",
            {
              formato: this.formato,
              proveedor: this.proveedor,
              usuario_id: this.soyAdmin
                ? this.cliente
                : localStorage.getItem("usuario_id"),
            },
            {
              headers: {
                authorization: localStorage.getItem("token"),
              },
            }
          );

          this.crearPDF(productos, precio_cliente);
        } catch (error) {
          this.makeToast(
            "Generar PDF",
            "Hubo un error al generar el archivo PDF",
            "danger",
            2000
          );
        }
      }
    },
    async generarMarca() {
      if (this.formato === "excel") {
        try {
          const stream = await axiosInstance.post(
            "listas-precios/marca",
            {
              formato: this.formato,
              proveedor: this.proveedor,
              marca: this.marca,
              usuario_id: this.soyAdmin
                ? this.cliente
                : localStorage.getItem("usuario_id"),
            },
            {
              responseType: "blob",
              headers: {
                authorization: localStorage.getItem("token"),
              },
            }
          );
          this.crearExcel(stream.data);
        } catch (error) {
          this.makeToast(
            "Generar Excel",
            "Hubo un error al generar el archivo Excel",
            "danger",
            2000
          );
        }
      } else {
        try {
          const {
            data: { productos, precio_cliente },
          } = await axiosInstance.post(
            "listas-precios/marca",
            {
              formato: this.formato,
              proveedor: this.proveedor,
              marca: this.marca,
              usuario_id: this.soyAdmin
                ? this.cliente
                : localStorage.getItem("usuario_id"),
            },
            {
              headers: {
                authorization: localStorage.getItem("token"),
              },
            }
          );

          this.crearPDF(productos, precio_cliente);
        } catch (error) {
          this.makeToast(
            "Generar PDF",
            "Hubo un error al generar el archivo PDF",
            "danger",
            2000
          );
        }
      }
    },
    async generarFamilia() {
      if (this.formato === "excel") {
        const stream = await axiosInstance.post(
          "listas-precios/familia",
          {
            formato: this.formato,
            proveedor: this.proveedor,
            familia: this.familia,
            usuario_id: this.soyAdmin
              ? this.cliente
              : localStorage.getItem("usuario_id"),
          },
          {
            responseType: "blob",
            headers: {
              authorization: localStorage.getItem("token"),
            },
          }
        );

        this.crearExcel(stream.data);
      } else {
        try {
          const {
            data: { productos, precio_cliente },
          } = await axiosInstance.post(
            "listas-precios/familia",
            {
              formato: this.formato,
              proveedor: this.proveedor,
              familia: this.familia,
              usuario_id: this.soyAdmin
                ? this.cliente
                : localStorage.getItem("usuario_id"),
            },
            {
              headers: {
                authorization: localStorage.getItem("token"),
              },
            }
          );

          this.crearPDF(productos, precio_cliente);
        } catch (error) {
          this.makeToast(
            "Generar PDF",
            "Hubo un error al generar el archivo PDF",
            "danger",
            2000
          );
        }
      }
    },
    async generarOferta() {
      if (this.formato === "excel") {
        const stream = await axiosInstance.post(
          "listas-precios/oferta",
          {
            formato: this.formato,
            proveedor: this.proveedor,
            usuario_id: this.soyAdmin
              ? this.cliente
              : localStorage.getItem("usuario_id"),
          },
          {
            responseType: "blob",
            headers: {
              authorization: localStorage.getItem("token"),
            },
          }
        );

        this.crearExcel(stream.data);
      } else {
        try {
          const {
            data: { productos, precio_cliente },
          } = await axiosInstance.post(
            "listas-precios/oferta",
            {
              formato: this.formato,
              proveedor: this.proveedor,
              usuario_id: this.soyAdmin
                ? this.cliente
                : localStorage.getItem("usuario_id"),
            },
            {
              headers: {
                authorization: localStorage.getItem("token"),
              },
            }
          );

          this.crearPDF(productos, precio_cliente);
        } catch (error) {
          this.makeToast(
            "Generar PDF",
            "Hubo un error al generar el archivo PDF",
            "danger",
            2000
          );
        }
      }
    },
    async generarNuevos() {
      if (this.formato === "excel") {
        const stream = await axiosInstance.post(
          "listas-precios/nuevos",
          {
            formato: this.formato,
            proveedor: this.proveedor,
            usuario_id: this.soyAdmin
              ? this.cliente
              : localStorage.getItem("usuario_id"),
          },
          {
            responseType: "blob",
            headers: {
              authorization: localStorage.getItem("token"),
            },
          }
        );

        this.crearExcel(stream.data);
      } else {
        try {
          const {
            data: { productos, precio_cliente },
          } = await axiosInstance.post(
            "listas-precios/nuevos",
            {
              formato: this.formato,
              proveedor: this.proveedor,
              usuario_id: this.soyAdmin
                ? this.cliente
                : localStorage.getItem("usuario_id"),
            },
            {
              headers: {
                authorization: localStorage.getItem("token"),
              },
            }
          );

          this.crearPDF(productos, precio_cliente);
        } catch (error) {
          this.makeToast(
            "Generar PDF",
            "Hubo un error al generar el archivo PDF",
            "danger",
            2000
          );
        }
      }
    },
    getProveedores(cliente) {
      this.loading = true;
      this.proveedor = null;
      this.cliente = cliente;
      axiosInstance
        .get("/proveedores/cliente", {
          params: { usuario_id: cliente },
          headers: {
            authorization: localStorage.getItem("token"),
          },
        })
        .then((result) => {
          this.proveedores = [{ value: null, text: "Seleccione un proveedor" }];
          result.data.forEach((proveedor) => {
            this.proveedores.push({
              value: proveedor.proveedor_id,
              text: proveedor.proveedor.nombre,
            });
          });
        })
        .catch(() => {
          this.makeToast(
            "Consulta proveedores",
            "Hubo un error al obtener los proveedores disponibles",
            "danger",
            2000
          );
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    soyAdmin() {
      return sessionStorage.getItem("admin") === "true";
    },
  },
};
</script>
